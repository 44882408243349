export const extractRouteFromCompany = (company) => {
    return `https://app.hubspot.com/contacts/${company.portalId}/company/${company.id}/`;
};

export const extractRouteFromContact = (contact) => {
    return `https://app.hubspot.com/contacts/${contact["portal-id"]}/contact/${contact["canonical-vid"]}/`;
};

export const extractOtherRouteFromEngagement = (engagement, routeLink) => {
    const { type, bodyPreview } = engagement;
    return {
        routeName: `${bodyPreview.split(/\s+/).slice(0,5).join(" ")}`,
        routeLink,
        type: `${type}`
    };
};

export const extractDecoratedCompany = (company, numberE164) => {
    return {
        nameFlow: `${company.properties.name}`,
        routeFlow: extractRouteFromCompany(company),
        numberE164Flow: numberE164,
    };
};

const getFirstNameFromContact = (contact) => {
    return contact &&
        contact.properties &&
        contact.properties.firstname &&
        contact.properties.firstname.value;
};

const getLastNameFromContact = (contact) => {
    return contact &&
        contact.properties &&
        contact.properties.lastname &&
        contact.properties.lastname.value;
};

const getContactName = (contact) => {
    const firstName = getFirstNameFromContact(contact);
    const lastName = getLastNameFromContact(contact);

    const names = [];
    if (firstName) {
        names.push(firstName);
    }
    if (lastName) {
        names.push(lastName);
    }

    return names.join(" ");
};

export const extractDecoratedContact = (contact, numberE164) => {
    return {
        nameFlow: getContactName(contact),
        routeFlow: extractRouteFromContact(contact),
        numberE164Flow: numberE164,
    };
};
