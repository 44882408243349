import AppConfig from "../appconfig";

export const getSearchCompaniesUrl = () => {
    return encodeURI("https://api.hubapi.com/crm/v3/objects/companies/search");
};

// https://legacydocs.hubspot.com/docs/methods/contacts/search_contacts
export const getContactByNumberUrl = (number) => {
    return encodeURI(`https://api.hubapi.com/contacts/v1/search/query?q=${number}&property=phone&property=lastname&property=firstname&property=associatedcompanyid`);
};

export const getDealsRequestUrlEncoded = (contactId, typeId) => {
    return encodeURI(`https://api.hubapi.com/crm-associations/v1/associations/${contactId}/HUBSPOT_DEFINED/${typeId}`);
};

export const getDealsBatchRequestUrlEncoded = () => {
    return encodeURI("https://api.hubapi.com/crm/v3/objects/deals/batch/read");
};

export const getPostEngagementsUrlEncoded = () => {
    return encodeURI("https://api.hubapi.com/engagements/v1/engagements");
};

export const getProxyUrl = () => {
    return `/proxy/${AppConfig.syncDataSource}/`;
};

export const getPortalIdUrl = () => {
    return encodeURI("https://api.hubapi.com/integrations/v1/me");
};

export const getRequestUrlParam = (url) => {
    return {
        requestURL: encodeURIComponent(url),
    };
};
