import "@babel/polyfill";
import CallingExtensions from "@hubspot/calling-extensions-sdk";
import { hubspot } from "./messageEvent";
import AppConfig from "./appconfig";
import { getUserId, registerUserId } from "./callLogUtils";
import { initAirBrake } from "@tvx/airbrake";
import { lookupNumbers } from "./contactLookup";
import { createCallLogEnded } from "./hubspotApi/engagementsApi";
import SweetSuite from "@tvx/sweet-suite";

console.info(`HubSpot CTI v${process.env.VERSION}`);

const frame = document.getElementById("telavox-cti");
frame.src = AppConfig.targetOrigin;

// This should be called asap in order to catch as many errors as possible.
const airbrake = initAirBrake(process.env.AIRBRAKE_PROJECT_ID, process.env.AIRBRAKE_PROJECT_KEY);

const config = {
    // Whether a user is logged-in
    isLoggedIn: false, // true
    // Optionally send the desired widget size
    widgetSize: {
        height: 500,
        width: 360
    }
};

const providedIframe = document.getElementById("telavox-cti");

const sweetSuiteOptions = {
    eventHandlers: {
        onGetContactDecorations: async (data) => {
            const { numbers } = data;
            let lookups = {};
            try {
                lookups = await lookupNumbers(sweetSuite, numbers);
            } catch (error) {
                numbers.forEach(number => {
                    lookups[number] = [];
                });
                console.warn("[WRAPPER] Received unexpected response formatting from contact lookup.", error.message);
                airbrake.notify(error);
            }
            return lookups;
        },
        onIncomingCall: (data) => {
            // If the widget is active, this method will ensure that the widget is made visible
            CallExt.incomingCall(data);
        },
        onOutgoingCallStarted: (data) => {
            CallExt.outgoingCall(data);
        },
        onParentRouteRequest: (data) => {
            const recordId = data.routeInfo;
            window.open(recordId, "_blank");
        },
        onUserLoggedIn: () => {
            CallExt.userLoggedIn();
        },
        onUserLoggedOut: () => {
            CallExt.userLoggedOut();
        },
        onCallAnswered: () => {
            CallExt.callAnswered();
        },
        onCallEnded: () => {
            CallExt.callEnded();
        },
        onCallLog: (data) => {
            const agentUserOwnerId = getUserId();
            const { call, myNumber, metadata, ctiSettings } = data;
            const logCallsAutomaticallySettingOn = ctiSettings.logCallsAutomatically.value;
            if (!logCallsAutomaticallySettingOn) {
                return;
            }
            const { objectId, objectType } = metadata || {};
            if (objectId && objectType && call) {
                const { durationMilliSeconds, recordingUrl, recordingPageUrl } = call;
                createCallLogEnded(sweetSuite, agentUserOwnerId, objectId, objectType, myNumber, call, recordingUrl, durationMilliSeconds, recordingPageUrl);
            } else {
                airbrake.notify(new Error(`Could not create call log. objectId: ${objectId}, objectType: ${objectType}, agentUserOwnerId: ${agentUserOwnerId}`));
            }
        },
        onInitiated: (data) => {
            const { loggedIn } = data;
            if (loggedIn !== undefined) {
                config.isLoggedIn = loggedIn;
            }
            CallExt.initialized(config);
            return {
                description: "HubSpot",
                ctiHostSetting:
                {
                    logCallsAutomatically: false
                }
            };
        }
    },
    delayListener: true,
    targetOrigin: AppConfig.targetOrigin,
    providedIframe: providedIframe.contentWindow,
    optionalAirbrake: airbrake,
};

const sweetSuite = new SweetSuite(sweetSuiteOptions);

const getHubspotConfigOptions = () => {
    const options = {
        // Whether to log various inbound/outbound messages to console, true for SDK logs
        debugMode: false,
        // eventHandlers handle inbound messages
        eventHandlers: {
            onReady: () => {
                console.info(`Hubspot wrapper (${AppConfig.appName}) is ready`);
                window.addEventListener("message", handleMessage, false);
                sweetSuite.startListener();
            },
            //Handler for the dial number event from Hubspot.
            onDialNumber: event => {
                const { phone_number } = event;
                sweetSuite.dialNumber(phone_number);
            },
            onVisibilityChanged: () => { /**/ },
            defaultEventHandler: () => { /**/ }
        }
    };
    return options;
};

const options = getHubspotConfigOptions();
const CallExt = new CallingExtensions(options);

export const handleMessage = async (event) => {
    switch (event.data.type) {
        case hubspot.DIAL_NUMBER: {
            const data = event.data.data;
            registerUserId(data.ownerId);
            break;
        }
        case hubspot.SET_CALL_STATE:
            break;
        case hubspot.SYNC:
            // handled by the Calling Extensions SDK
            break;
    }
};
