export const hubspot = {
    SET_CALL_STATE: "SET_CALL_STATE",
    SYNC: "SYNC",
    DIAL_NUMBER: "DIAL_NUMBER"
};

export const tvx = {
    REGISTER_ORIGIN: "REGISTER_ORIGIN",
    GET_CONTACT_DECORATIONS: "GET_CONTACT_DECORATIONS",
    PARENT_ROUTE_REQUEST: "PARENT_ROUTE_REQUEST",
    USER_LOGGED_IN: "USER_LOGGED_IN",
    USER_LOGGED_OUT: "USER_LOGGED_OUT",
    INCOMING_CALL: "INCOMING_CALL",
    OUTGOING_CALL_STARTED: "OUTGOING_CALL_STARTED",
    CALL_ENDED: "CALL_ENDED",
    CALL_ANSWERED: "CALL_ANSWERED",
    CONTACT_DECORATIONS_RESPONSE: "CONTACT_DECORATIONS_RESPONSE",
    CALL_LOG: "CALL_LOG",
    FEATURE_FLAGS: "FEATURE_FLAGS",
    PROXY_REQUEST: "PROXY_REQUEST",
    PROXY_REQUEST_RESPONSE: "PROXY_REQUEST_RESPONSE",
};
