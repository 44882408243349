import { extractDataField, handleResponse } from "../asyncUtils";
import { getContactByNumberUrl, getProxyUrl, getRequestUrlParam } from "./urlUtils";

export const extractContacts = async (data) => {
    if (!data) {
        throw new Error("No data to extract contacts from!");
    }
    const { contacts } = data;
    if (!contacts) {
        throw new Error("No contacts found in data!");
    }
    return contacts;
};

export const getContactsByNumber = async (sweetSuite, number) => {
    const requestUrl = getContactByNumberUrl(number);
    const requestUrlParam = getRequestUrlParam(requestUrl);
    const proxyUrl = getProxyUrl();

    return sweetSuite.proxyRequest(proxyUrl, "GET", { params: requestUrlParam })
        .then(handleResponse)
        .then(extractDataField)
        .then(extractContacts);
};
