export const getWebappUrl = () => {
    const host = location.hostname;
    if (host.includes("joinflow.com")) {
        return "https://app.joinflow.com/embed/integration?config=HubSpot";
    } else {
        return process.env.TARGET_ORIGIN;
    }
};

export const getSyncDataSource = () => {
    const host = location.hostname;
    if (host.includes("joinflow.com")) {
        return "hubspot_flow";
    } else {
        return process.env.SYNC_DATA_SOURCE;
    }
};
