import { getContactsByNumber } from "./hubspotApi/contactApi";
import { getCompaniesByNumber } from "./hubspotApi/companyApi";
import { getPortalId } from "./hubspotApi/accountApi";
import { extractDecoratedCompany, extractDecoratedContact } from "./hubspotApi/responseTransformers";
import { OBJECT_TYPES } from "./hubspotApi/constants";


/**
 * Will look up contacts for the given numbers. If a hit is found, it is decorated
 * with the information expected by the CTI. Lookup order is as follows;
 * > Look for a contact with "numberE164"
 * > Look for a company with "numberE164"
 * @returns Decorated contacts for each number that got a hit, or an empty array if nothing is found
 * @throws If network request can't be performed, is not successful or the returned data is not structured as expected.
 */
export async function lookupNumbers(sweetSuite, numbers) {

    /**
     * Normally we would populate contacts with each number in numbers as keys,
     * and an array with potential contacts as values. But since we don't do lookups
     * for the call history in Hubspot, that will just cause the call history to be
     * updated with no contacts every time we navigate to it, which means that the contacts
     * we have called won't be saved. This solution however, won't work with a potential
     * future implementation of the puzzle button that shows the different contactRouteButtons
     * for a contact in the call history, since it requires an array (even empty) for the key number,
     * in order to show the button.
     */
    const contacts = {};

    // Because HubSpot has fairly strict rate limits and not very good APIs to search on multiple numbers at once
    // we do not do lookups on the history. This if case signifies that lookup is requested for an ongoing call.
    if (numbers.length === 1) {
        const numberToLookUp = numbers[0];
        contacts[numberToLookUp] = [];

        const lookupContactsResponse = await getContactsByNumber(sweetSuite, numberToLookUp);
        for (const contact of lookupContactsResponse) {

            const objectType = OBJECT_TYPES.CONTACT;
            const objectId = contact.vid;

            const decoratedContact = extractDecoratedContact(contact, numberToLookUp);
            decoratedContact["metadata"] = {
                objectId,
                objectType,
            };
            contacts[numberToLookUp].push(decoratedContact);
        }

        // when doing the http requests with older versions we get the portal id returned from hubspot,
        // but for lookupCompanies we use the new API (v3), which doesn't return the portal id,
        // which is why we have to get it here.
        const portalId = await getPortalId(sweetSuite);
        const lookupCompaniesResponse = await getCompaniesByNumber(sweetSuite, numberToLookUp);
        const companiesResults = lookupCompaniesResponse.results;
        for (const company of companiesResults) {

            const objectType = OBJECT_TYPES.COMPANY;
            const objectId = company.id;
            company["portalId"] = portalId;

            const decoratedCompany = extractDecoratedCompany(company, numberToLookUp);
            decoratedCompany["metadata"] = {
                objectId,
                objectType,
            };
            contacts[numberToLookUp].push(decoratedCompany);
        }
    }
    return contacts;
}
