import { extractDataField, handleResponse } from "../asyncUtils";
import { getSearchCompaniesUrl, getProxyUrl } from "./urlUtils";

export const getCompaniesByNumber = async (sweetSuite, number) => {
    const requestUrl = getSearchCompaniesUrl();
    const proxyUrl = getProxyUrl();

    const body = {
        data: {
            "filterGroups": [
                {
                    "filters": [
                        {
                            "propertyName": "phone",
                            "operator": "EQ",
                            "value": `${number}`,
                        },
                    ],
                },
            ],
        },
        requestURL: requestUrl,
    };

    return sweetSuite.proxyRequest(proxyUrl, "POST", body)
        .then(handleResponse)
        .then(extractDataField);
};
