export const handleResponse = (res) => {
    if (res && res.ok && res.status === 200) {
        return res.body;
    }
    const errorMessage = `API call response was not status 200/ok.
        Status: ${res && res.status}, 
        Ok: ${res && res.ok}, 
        statusText: ${JSON.stringify(res && res.statusText)}, 
        response: ${JSON.stringify(res && res.response)}, 
        responseText: ${JSON.stringify(res && res.responseText)}`;
    throw new Error(errorMessage);
};

export const extractDataField = (body) => {
    if (body) {
        return body;
    }
    throw new Error(`API call response missing data: ${body}`);
};

export const extractResults = (data) => {
    const { results } = data ? data : {};
    return Array.isArray(results) ? results : [];
};
