import { handleResponse } from "../asyncUtils";
import { OBJECT_TYPES } from "./constants";
import { getPostEngagementsUrlEncoded, getProxyUrl } from "./urlUtils";
import { getAppName } from "../callLogUtils";

export const createAssociations = (objectId, objectType) => {
    switch (objectType) {
        case OBJECT_TYPES.CONTACT : return { contactIds: [ objectId ]};
        case OBJECT_TYPES.COMPANY : return { companyIds: [ objectId ]};
        default: return null;
    }
};

/**
 * Creates the body for a call log POST request.
 * @param {*} call { number, direction }
 * @param {*} ownerId The HubSpot id of the person that owns the engagement, not the contact itself.
 * @param {*} objectId The id of the object we are logging a call to.
 * @param {*} objectType The type of the object we are logging a call to.
 * @param {*} myNumber The agents number.
 * @param {*} recordingUrl The url to the call recording.
 * @param {*} durationMilliseconds The duration of the call in milliseconds.
 * @param {*} recordingPageUrl The url to the callrecording page.
 */
export const createCallEngagementBody = (call, ownerId, objectId, objectType, myNumber, recordingUrl, durationMilliseconds, recordingPageUrl) => {
    const requestURL = getPostEngagementsUrlEncoded();
    const associations = createAssociations(objectId, objectType);
    const isOutgoing = call.direction === "Outgoing";
    const toNumber = isOutgoing ? call.number : myNumber;
    const fromNumber = isOutgoing ? myNumber : call.number;
    let body = `${call.direction} call via ${getAppName()}`;

    if (recordingUrl) {
        body = `${body}.</br></br>`;
        body = `${body}<i>If you are unable to listen to the recording;</i>`;
        body = `${body}<ul>`;
        body = `${body}<li><i>Wait for the recording to finish loading</i></li>`;
        body = `${body}<li><i>Make sure you have logged in to the Telavox calling extension</i></li>`;
        body = `${body}<li><i>Make sure you have the correct user rights in Telavox to listen to the recording</i></li>`;
        body = `${body}</ul>`;
        body = `${body}<i>Call record details can be found <a href="${recordingPageUrl}">here</a>.</i>`;
    }

    return {
        data: {
            engagement: {
                type: "CALL",
                ownerId
            },
            associations,
            metadata: {
                toNumber,
                fromNumber,
                status: "COMPLETED",
                body,
                ...durationMilliseconds && { durationMilliseconds },
                ...recordingUrl && { recordingUrl },
            }
        },
        requestURL
    };
};

export const createCallLogEnded = async (sweetSuite, ownerId, objectId, objectType, myNumber, call, routeInput, recordingUrl, durationMilliSeconds) => {
    const postBody = createCallEngagementBody(call, ownerId, objectId, objectType, myNumber, routeInput, recordingUrl, durationMilliSeconds);
    await sweetSuite.proxyRequest(getProxyUrl(), "POST", postBody)
        .then(handleResponse);
};
