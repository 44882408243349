const USER_ID = "userID";

export const registerUserId = (id) => {
    localStorage.setItem(USER_ID, JSON.stringify(id));
};
export const getUserId = () => {
    return JSON.parse(localStorage.getItem(USER_ID));
};

export const getAppName = () => {
    const hostName = window.location.hostname;
    return hostName === "app.joinflow.com" ? "Flow" : "Telavox";
};
