import { extractDataField, handleResponse } from "../asyncUtils";
import { getProxyUrl, getRequestUrlParam, getPortalIdUrl } from "./urlUtils";

export const getPortalId = async (sweetSuite) => {
    const requestUrl = getPortalIdUrl();
    const proxyUrl = getProxyUrl();
    const requestUrlParam = getRequestUrlParam(requestUrl);

    const response = await sweetSuite.proxyRequest(proxyUrl, "GET", { params: requestUrlParam });
    const handledResponse = handleResponse(response);
    const data = extractDataField(handledResponse);
    return extractPortalId(data);
};

export const extractPortalId = (data) => {
    if (!data) {
        throw new Error("No data to extract portal id from!");
    }
    const { portalId } = data;
    if (!portalId) {
        throw new Error("No portal id found in data!");
    }
    return portalId;
};
